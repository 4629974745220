<template>
  <v-container class="mx-auto">
    <v-alert
        text
        color="teal"
        icon="mdi-database-settings"
        border="left"
    >
      没有了, 没有了, 真的一篇文章都不剩了...
    </v-alert>
  </v-container>
</template>

<script>
export default {
name: "NoMore"
}
</script>
